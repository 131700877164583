import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  todo_list: null,
  todo_lists: null,
};

export const todoListSlice = createSlice({
  name: "todo_list",
  initialState,
  reducers: {
    setTodoList: (state, action) => {
      state.todo_list = action.payload;
    },
    setAllTodoList: (state, action) => {
      state.todo_lists = action.payload;
    },
  },
});

// Get todo list by user id
export const getTodoListAsync = (user_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/todo/get_todo/" + user_id
    );
    dispatch(setTodoList(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get todo list
export const getAllTodoListAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/todo/get_all_todo"
    );
    dispatch(setAllTodoList(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setTodoList, setAllTodoList } = todoListSlice.actions;
export const showTodoList = (state) => state.todo_list.todo_list;
export const showAllTodoList = (state) => state.todo_list.todo_lists;
export default todoListSlice.reducer;
