import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  profile: null,
  profiles: [],
  loading: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileLoading: (state) => {
      state.loading = true;
    },
    setCurrentUserProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAllUser: (state, action) => {
      state.profiles = action.payload;
    },
    deleteAccount: (state, action) => {},
  },
});

// Get current profile
export const getCurrentUserAsync = (user_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/profile/" + user_id
    );
    dispatch(setCurrentUserProfile(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get all clients profile
export const getAllClientsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/profile"
    );
    dispatch(setAllUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get all clients by type
export const getClientsAsync =
  (type = "active") =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL +
          "api/profile/get_client_by_type/" +
          type
      );
      if (response.data) {
        dispatch(setAllUser(response.data));
      } else {
        dispatch(setAllUser([]));
      }
    } catch (err) {
      dispatch(setAllUser([]));
      throw new Error(err);
    }
  };

// Create Profile Pro
export const createProfileProAsync = (profileData) => async (dispatch) => {
  try {
    await axios.post(
      process.env.REACT_APP_API_BASE_URL + "api/profile/pro",
      profileData
    );
    dispatch(getCurrentUserAsync);
  } catch (err) {
    throw new Error(err);
  }
};

export const { setCurrentUserProfile, setAllUser, deleteAccount } =
  profileSlice.actions;
export const showCurrentUser = (state) => state.profile.profile;
export const showAllUsers = (state) => state.profile.profiles;

export default profileSlice.reducer;
