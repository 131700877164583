import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  site_setting: null,
};

export const siteSettingSlice = createSlice({
  name: "site_setting",
  initialState,
  reducers: {
    setSiteSetting: (state, action) => {
      state.site_setting = action.payload;
    },
  },
});

// Get site setting
export const getSiteSettingAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/site_setting/get_site_setting"
    );
    dispatch(setSiteSetting(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setSiteSetting } = siteSettingSlice.actions;
export const showSiteSetting = (state) => state.site_setting.site_setting;
export default siteSettingSlice.reducer;
