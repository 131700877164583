import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  gallery: null,
  loading: false,
};

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    setGallery: (state, action) => {
      state.gallery = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
  },
});

// Get galleries
export const getGalleryByCategoryAsync =
  (category = "") =>
  (dispatch) => {
    dispatch(setLoading());
    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          "api/gallery/get_gallery_by_category/" +
          category
      )
      .then((res) => dispatch(setGallery(res.data)))
      .catch((err) => {
        console.log(err);
        dispatch(setGallery([]));
      });
  };

export const { setGallery, setLoading } = gallerySlice.actions;
export const showGallery = (state) => state.gallery.gallery;
export const showLoading = (state) => state.gallery.loading;
export default gallerySlice.reducer;
