import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import notify from "../../utils/notify";
import setAuthToken from "../../utils/setAuthToken";
import { setCurrentUser } from "../../app/authSlice";
import { setCurrentUserProfile } from "../../app/profileSlice";

const Forgot = ({ show, closeModal }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");
  const [state, setState] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  const sendEmail = (e) => {
    e.preventDefault();

    const user = {
      email: email,
    };

    axios
      .post(process.env.REACT_APP_API_BASE_URL + "api/auth/forgot", user)
      .then((res) => {
        setState(1);
      })
      .catch((err) => setErrors(err.response.data));
  };

  const sendCode = (e) => {
    e.preventDefault();

    const codeData = {
      token: code,
    };

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/check-forgot-token",
        codeData
      )
      .then((res) => {
        setState(2);
      })
      .catch((err) => setErrors(err.response.data));
  };

  const resetPassword = (e) => {
    e.preventDefault();

    const user = {
      email,
      password,
      password2,
    };

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "api/auth/reset-password",
        user
      )
      .then((res) => {
        closeModal();
        notify("You have successfully reset your password!", 1);

        // Login
        axios
          .post(process.env.REACT_APP_API_BASE_URL + "api/auth/login", user)
          .then((res) => {
            // Save to localstorage
            const { token } = res.data;

            // Set token to localstorage
            localStorage.setItem("jwtToken", token);

            // Set token to Auth header
            setAuthToken(token);

            // Decode token to get user data
            const decoded = jwt_decode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setCurrentUserProfile(decoded));

            notify("You have successfully logged in!", 1);
          })
          .catch((err) => setErrors(err.response.data));
      })
      .catch((err) => setErrors(err.response.data));
  };

  return (
    <div
      className="hidden fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full justify-center items-center"
      style={{ display: show === "forgot" ? "flex" : "none" }}
    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-white shadow">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={closeModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          {state === 0 ? (
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">
                Forgot password
              </h3>
              <form className="space-y-6" noValidate onSubmit={sendEmail}>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Your email
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      name="email"
                      type="email"
                      value={email}
                      autoComplete="email"
                      onChange={(e) => {
                        setEmail(
                          e.target.value.replace(/\s+/g, "").toLowerCase()
                        );
                      }}
                    />
                    {errors.email && (
                      <p className="mt-2 text-xs text-red-600">
                        <span className="font-medium">Oh, snapp!</span>{" "}
                        {errors.email}
                      </p>
                    )}
                  </label>
                </div>
                <div className="flex justify-between text-[12px] font-semibold">
                  You will receive a verification code if your email is
                  registered.
                </div>
                <button
                  type="submit"
                  className="w-full text-white hover:text-[#F8981D] bg-[#F8981D] hover:bg-white border hover:border-[#F8981D] font-medium text-sm px-5 py-2.5 text-center"
                >
                  Email code
                </button>
              </form>
            </div>
          ) : state === 1 ? (
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">
                Forgot password
              </h3>
              <form className="space-y-6" noValidate onSubmit={sendCode}>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Verify code
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      name="code"
                      type="text"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                    {errors.code && (
                      <p className="mt-2 text-xs text-red-600">
                        <span className="font-medium">Oh, snapp!</span>{" "}
                        {errors.code}
                      </p>
                    )}
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full text-white hover:text-[#F8981D] bg-[#F8981D] hover:bg-white border hover:border-[#F8981D] font-medium text-sm px-5 py-2.5 text-center"
                >
                  Send code
                </button>
              </form>
            </div>
          ) : state === 2 ? (
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">
                Reset password
              </h3>
              <form className="space-y-6" noValidate onSubmit={resetPassword}>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Password
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    {errors.password && (
                      <p className="mt-2 text-xs text-red-600">
                        <span className="font-medium">Oh, snapp!</span>{" "}
                        {errors.password}
                      </p>
                    )}
                  </label>
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Confirm password
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      name="password2"
                      type="password"
                      value={password2}
                      onChange={(e) => {
                        setPassword2(e.target.value);
                      }}
                    />
                    {errors.password2 && (
                      <p className="mt-2 text-xs text-red-600">
                        <span className="font-medium">Oh, snapp!</span>{" "}
                        {errors.password2}
                      </p>
                    )}
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full text-white hover:text-[#F8981D] bg-[#F8981D] hover:bg-white border hover:border-[#F8981D] font-medium text-sm px-5 py-2.5 text-center"
                >
                  Submit
                </button>
              </form>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Forgot;
