import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import profileSlice from "./profileSlice";
import bookingSlice from "./bookingSlice";
import bookSettingSlice from "./bookSettingSlice";
import siteSettingSlice from "./siteSettingSlice";
import todoListSlice from "./todoListSlice";
import notificationSlice from "./notificationSlice";
import productSlice from "./productSlice";
import gallerySlice from "./gallerySlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
    booking: bookingSlice,
    booking_setting: bookSettingSlice,
    site_setting: siteSettingSlice,
    todo_list: todoListSlice,
    notification: notificationSlice,
    product: productSlice,
    gallery: gallerySlice,
  },
});
