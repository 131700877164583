import React from "react";
import axios from "axios";
import notify from "../../utils/notify";

const Suspend = ({ show, closeModal }) => {
  return (
    <div
      className="hidden fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full justify-center items-center"
      style={{ display: show === "suspend" ? "flex" : "none" }}
    >
      <div className="relative w-full h-full max-w-md md:h-auto">
        <div className="relative bg-white shadow">
          <button
            id="login-close-btn"
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={closeModal}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900">
              Account Suspended
            </h3>
            <div className="font-medium text-gray-900">
              <p>
                Sorry, your account has been suspended. Don't worry, this
                problem may easily be resolved.
              </p>
              <p>
                Please send an email to{" "}
                <a
                  className="underline decoration-[#F8981D] text-[#F8981D]"
                  href="mailto:besthandymaneversf@gmail.com?&subject=Request%20for%20Reinstatement&body=Request%20for%20Reinstatement.%20Please%20enter%20details%20below.---------------"
                  target="_top"
                >
                  Request Reinstatement
                </a>
              </p>
              <p className="mt-3">
                Thank you. <br />
                -Best Handyman Ever
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suspend;
