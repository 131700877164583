import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  location_charge_setting: null,
  booking_setting: null,
};

export const bookingSettingSlice = createSlice({
  name: "booking_setting",
  initialState,
  reducers: {
    setLocationChargeSetting: (state, action) => {
      state.location_charge_setting = action.payload;
    },
    setBookingSetting: (state, action) => {
      state.booking_setting = action.payload;
    },
  },
});

// Get location charge setting
export const getLocationChargeSettingAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking_setting/get_map_setting"
    );
    dispatch(setLocationChargeSetting(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get Booking setting
export const getBookingSettingAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/booking_setting/get_same_day_setting"
    );
    dispatch(setBookingSetting(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setLocationChargeSetting, setBookingSetting } =
  bookingSettingSlice.actions;
export const showLocationChargesetting = (state) =>
  state.booking_setting.location_charge_setting;
export const showBookingsetting = (state) =>
  state.booking_setting.booking_setting;

export default bookingSettingSlice.reducer;
