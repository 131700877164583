import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyB0TRBxyNclexjqdDlKD9VxrkME8KW3QSw",
  authDomain: "besthandymanever.firebaseapp.com",
  projectId: "besthandymanever",
  storageBucket: "besthandymanever.appspot.com",
  messagingSenderId: "205928242237",
  appId: "1:205928242237:web:3a95158cbc80326f089e54",
  measurementId: "G-39T95WGSFV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get messaging instance
const messaging = getMessaging(app);

export { messaging, getToken };
