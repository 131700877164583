import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  unavailable_time: null,
  bookings: null,
  all_bookings: null,
  past_bookings: null,
  future_bookings: null,
  loading: false,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setUnavailableTime: (state, action) => {
      state.unavailable_time = action.payload;
      state.loading = false;
    },
    setBookings: (state, action) => {
      state.bookings = action.payload;
    },
    setAllBookings: (state, action) => {
      state.all_bookings = action.payload;
    },
    setPastBookings: (state, action) => {
      state.past_bookings = action.payload;
    },
    setFutureBookings: (state, action) => {
      state.future_bookings = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
  },
});

// Get book setting
export const getUnavailableTimeAsync = (date) => (dispatch) => {
  dispatch(setLoading());
  axios
    .post(
      process.env.REACT_APP_API_BASE_URL +
        "api/booking/get_unavailable_time_from_gcalendar/" +
        date
    )
    .then((res) => dispatch(setUnavailableTime(res.data)))
    .catch((err) => console.log(err));
};

// Get All bookings
export const getAllBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_all_bookings"
    );
    dispatch(setAllBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get bookings
export const getBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_bookings"
    );
    dispatch(setBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get past bookings
export const getPastBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_past_bookings"
    );
    dispatch(setPastBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get past bookings by date range
export const getPastBookingsByDateRangeAsync =
  (start_date, end_date) => async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}api/booking/get_past_bookings_date_range`,
        {
          params: {
            start_date,
            end_date,
          },
        }
      );
      dispatch(setPastBookings(response.data));
    } catch (err) {
      throw new Error(err);
    }
  };

// Get all past bookings
export const getAllPastBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_all_past_bookings"
    );
    dispatch(setPastBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get future bookings
export const getFutureBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_future_bookings"
    );
    dispatch(setFutureBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get all future bookings
export const getAllFutureBookingsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/booking/get_all_future_bookings"
    );
    dispatch(setFutureBookings(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const {
  setUnavailableTime,
  setBookings,
  setAllBookings,
  setPastBookings,
  setFutureBookings,
  setLoading,
} = bookingSlice.actions;
export const showUnavailableTime = (state) => state.booking.unavailable_time;
export const showBookings = (state) => state.booking.bookings;
export const showAllBookings = (state) => state.booking.all_bookings;
export const showPastBookings = (state) => state.booking.past_bookings;
export const showFutureBookings = (state) => state.booking.future_bookings;
export const showLoading = (state) => state.booking.loading;
export default bookingSlice.reducer;
