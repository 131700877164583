import React from "react";

const Hero = () => {
  return (
    <div
      className="w-full bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(assets/img/hero-bg.webp)` }}
    >
      <div className="lg:ml-[80px] pt-[106px] lg:pt-[241px] pb-[178px] lg:pb-[285px] tracking-tighter text-center lg:text-left">
        <div className="text-[50px] sm:text-[70px] text-white font-bold">
          Best <span className="text-[#F8981D]">Handyman</span> Ever
        </div>
        <h1 className="font-medium text-white text-[18px] sm:text-[20px]">
          Bay Area Handyman
        </h1>
        <button
          className="w-fit bg-black px-[29px] py-[13px] mt-[40px] text-white font-bold text-center mx-auto lg:mx-[0px] border-2 border-[#F8981D] hover:bg-[#F8981D]"
          onClick={() => document.getElementById("book-btn").click()}
        >
          SCHEDULE SERVICE
        </button>
      </div>
    </div>
  );
};

export default Hero;
