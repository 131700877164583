import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { Toaster } from "react-hot-toast";
import isEmpty from "../../utils/is-empty";
import { logoutUser } from "../../app/authSlice";
import Topbar from "./Topbar";
import Header from "./Header";
import Hero from "./Hero";
import About from "./About";
import Services from "./Services";
import Process from "./Process";
import Rental from "./Rental";
import Gallery from "./Gallery";
import Testimonial from "./Testimonial";
import Appointment from "./Appointment";
import Footer from "./Footer";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Forgot from "../auth/Forgot";
import Suspend from "../auth/Suspend";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState("none");
  const [redirect, setRedirect] = useState("");

  if (localStorage.jwtToken) {
    var decoded = jwt_decode(localStorage.jwtToken);
  }

  useEffect(() => {
    if (location.hash) {
      window.location.href = location.hash;
    }
  }, [dispatch]);

  const bookAppointment = (categorySn) => {
    if (isEmpty(decoded)) {
      window.scrollTo(0, 0);
      setRedirect("/dashboard/booking/" + categorySn);
      setModal("login");
      document.body.style.overflow = "hidden";
    } else {
      navigate("/dashboard/booking/" + categorySn);
    }
  };

  const closeModal = () => {
    setModal("none");
    document.body.style.overflow = "auto";
  };

  const logout = (e) => {
    dispatch(logoutUser());
  };

  return (
    <div>
      <Topbar setModal={(e) => setModal(e)} logout={logout} />
      <Header bookAppointment={() => bookAppointment("default")} />
      <Hero bookAppointment={() => bookAppointment("default")} />
      <About />
      <Services bookAppointment={bookAppointment} />
      <Process />
      <Rental bookAppointment={bookAppointment} />
      <Gallery />
      <Testimonial />
      <Appointment bookAppointment={() => bookAppointment("default")} />
      <Footer bookAppointment={() => bookAppointment("default")} />
      <Login
        show={modal}
        redirect={redirect}
        closeModal={closeModal}
        navModal={(e) => setModal(e)}
      />
      <Register
        show={modal}
        closeModal={closeModal}
        navModal={() => setModal("login")}
      />
      <Forgot
        show={modal}
        redirect={redirect}
        closeModal={closeModal}
        navModal={(e) => setModal(e)}
      />
      <Suspend show={modal} closeModal={closeModal} />
      <div
        className="absolute w-full h-[100%] bg-[#010101] bg-opacity-80 z-[49] left-0 top-0"
        style={{ display: modal === "none" ? "none" : "block" }}
      />
      <Toaster position="top-right" />
    </div>
  );
};

export default Home;
