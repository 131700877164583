import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showSiteSetting,
  getSiteSettingAsync,
} from "../../app/siteSettingSlice";

const About = () => {
  const dispatch = useDispatch();
  const settings = useSelector(showSiteSetting);

  useEffect(() => {
    dispatch(getSiteSettingAsync());
  }, [dispatch]);

  useEffect(() => {
    if (settings) {
      document.getElementById("about_me_text").innerHTML = settings.aboutMe;
    }
  }, [settings]);

  return (
    <div className="w-full bg-[#151515] shadow-[0_0_10px_1px_rgba(0, 0, 0, 0.25)] mt-[-20px]">
      <div className="w-full max-w-[1280px] lg:translate-y-[-82px] mx-auto bg-[#FFFFFF] shadow-[0_0_10px_1px_rgba(0,0,0,25)] py-[40px] px-[35px] flex flex-col lg:flex-row justify-between">
        <div className="basis-1/3 flex flex-col items-center px-[35px] lg:ob-0 border-b-2 lg:border-b-0 lg:border-r-2 border-solid border-[#666464]">
          <img
            className="w-[69px] h-[69px]"
            src="/assets/img/about-icon1.webp"
            alt="about"
          />
          <div className="w-[217px] mt-[27px] text-center font-semibold text-[20px] leading-[23px] text-[#414141]">
            Large Number of Services Provided
          </div>
          <div className="w-full mt-[18px] mb-[60px] lg:mb-[0px] text-center text-[16px] leading-[19px] text-[#666464]">
            I provide a wide range of maintenance and many other services.
          </div>
        </div>
        <div className="basis-1/3 flex flex-col items-center mt-[60px] lg:mt-[0px] px-[35px] lg:ob-0 border-b-2 lg:border-b-0 lg:border-r-2 border-solid border-[#666464]">
          <img
            className="w-[69px] h-[69px] border-solid"
            src="/assets/img/about-icon2.webp"
            alt="about"
          />
          <div className="w-[217px] mt-[27px] text-center font-semibold text-[20px] leading-[23px] text-[#414141]">
            10 Years+ of Professional Experience
          </div>
          <div className="w-full mt-[18px] mb-[60px] lg:mb-[0px] text-center text-[16px] leading-[19px] text-[#666464]">
            I work to provide quality workmanship and long lasting solutions.
          </div>
        </div>
        <div className="basis-1/3 flex flex-col items-center px-[35px] mt-[60px] lg:mt-[0px] ">
          <img
            className="w-[69px] h-[69px] border-solid"
            src="assets/img/about-icon3.webp"
            alt="about"
          />
          <div className="w-[217px] mt-[27px] text-center font-semibold text-[20px] leading-[23px] text-[#414141]">
            Large Numbers of Grateful Customers
          </div>
          <div className="w-full mt-[18px] text-center text-[16px] leading-[19px] text-[#666464]">
            I have hundreds of happy customers! One of them likely referred you
            to me. Take a look at my reviews!
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 h-auto lg:h-[709px]">
          <img
            id="about"
            className="w-full h-full object-center object-cover"
            src="assets/img/about.webp"
            alt="about"
          />
        </div>
        <div className="w-full lg:w-1/2 px-[20px] py-[90px] lg:p-[54px]">
          <div className="w-full text-center lg:text-left">
            <h2 className="text-[#F8981D] text-[16px] sm:text-[20px] leading-[23px] font-black tracking-tighter ">
              ABOUT ME
            </h2>
            <h1 className="text-[#FFFFFF] text-[30px] sm:text-[40px] font-bold leading-[47px] mt-[15px] text-center lg:text-left">
              Best Handyman Service in <br></br>The San Francisco
              <span className="text-[#F8981D]"> Bay Area</span>
            </h1>
            <div
              id="about_me_text"
              className="text-[#FFFFFF] text-[16px] font-semibold leading-[19px] mt-[29px]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
