import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const Testimonial = () => {
  return (
    <div id="testimonials" className="w-full flex flex-col items-center">
      <h2 className="text-white border-b-4 border-[#F8981D] bg-black px-2 py-1 font-black text-[16px] sm:text-[20px] leading-[23.44px] mt-[68px]">
        TESTIMONIALS
      </h2>
      <h3 className="w-full max-w-[600px] text-[#151515] text-center font-extrabold text-[32px] sm:text-[40px] leading-[47px] mt-[26px]">
        My Customers Say...
      </h3>
      <div className="swiper-container w-full -mt-10">
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          spaceBetween={16}
          modules={[Autoplay, Pagination, Navigation]}
          className="w-full"
        >
          <SwiperSlide key={1}>
            <figure className="absolute block max-w-screen-md h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mx-auto text-center">
              <svg
                aria-hidden="true"
                className="w-12 h-12 mx-auto mb-3 text-[#F8981D] text-[200px]"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <blockquote>
                <p className="italic text-[16px] sm:text-[20px] leading-[23px] text-center">
                  Augustino was fantastic to work with - responsive, reliable,
                  prompt, knowledgeable, and friendly. He ran into a few issues
                  installing my new toilet because of errors during the original
                  install, but was able to solve the problem. I highly recommend
                  him!
                </p>
              </blockquote>
              <figcaption className="flex items-center justify-center mt-6 space-x-3">
                <div className="flex items-center divide-x-2 divide-gray-500">
                  <cite className="pr-3 font-semibold text-[#F8981D] text-[20px]">
                    -Shauna S
                  </cite>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <figure className="absolute block max-w-screen-md h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mx-auto text-center">
              <svg
                aria-hidden="true"
                className="w-12 h-12 mx-auto mb-3 text-[#F8981D] text-[200px]"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <blockquote>
                <p className="text-2xl italic text-[16px] sm:text-[20px] leading-[23px] text-center">
                  Great attention to detail. Friendly and hard working. Highly
                  recommend.
                </p>
              </blockquote>
              <figcaption className="flex items-center justify-center mt-6 space-x-3">
                <div className="flex items-center divide-x-2 divide-gray-500">
                  <cite className="pr-3 font-semibold text-[#F8981D] text-[20px]">
                    -Bridget f
                  </cite>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <figure className="absolute block max-w-screen-md h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mx-auto text-center">
              <svg
                aria-hidden="true"
                className="w-12 h-12 mx-auto mb-3 text-[#F8981D] text-[200px]"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <blockquote>
                <p className="text-2xl italic text-[16px] sm:text-[20px] leading-[23px] text-center">
                  Wonderful job and super supportive!! Very professional and
                  helped us with a number of home issues, including the toilet
                  being clogged, the shower drain being stopped up, the knobs on
                  our doors not working properly and the shower knobs falling
                  off.
                </p>
              </blockquote>
              <figcaption className="flex items-center justify-center mt-6 space-x-3">
                <div className="flex items-center divide-x-2 divide-gray-500">
                  <cite className="pr-3 font-semibold text-[#F8981D] text-[20px]">
                    Ree B
                  </cite>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
          <SwiperSlide key={4}>
            <figure className="absolute block max-w-screen-md h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mx-auto text-center">
              <svg
                aria-hidden="true"
                className="w-12 h-12 mx-auto mb-3 text-[#F8981D] text-[200px]"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <blockquote>
                <p className="text-2xl italic text-[16px] sm:text-[20px] leading-[23px] text-center">
                  Really great to work with. Completely recommend!
                </p>
              </blockquote>
              <figcaption className="flex items-center justify-center mt-6 space-x-3">
                <div className="flex items-center divide-x-2 divide-gray-500">
                  <cite className="pr-3 font-semibold text-[#F8981D] text-[20px]">
                    -Adam B
                  </cite>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
          <SwiperSlide key={5}>
            <figure className="absolute block max-w-screen-md h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 mx-auto text-center">
              <svg
                aria-hidden="true"
                className="w-12 h-12 mx-auto mb-3 text-[#F8981D] text-[200px]"
                viewBox="0 0 24 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"
                />
              </svg>
              <blockquote>
                <p className="text-2xl italic text-[16px] sm:text-[20px] leading-[23px] text-center">
                  Augustino did a great job both times we've needed his help. He
                  was professional, thorough, clean, and friendly - he’s our guy
                  for any issues in the bathroom!
                </p>
              </blockquote>
              <figcaption className="flex items-center justify-center mt-6 space-x-3">
                <div className="flex items-center divide-x-2 divide-gray-500">
                  <cite className="pr-3 font-semibold text-[#F8981D] text-[20px]">
                    -Gur B
                  </cite>
                </div>
              </figcaption>
            </figure>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonial;
