import React from "react";

const Process = () => {
  return (
    <div className="w-full bg-[#151515] flex flex-col items-center mt-[68px]">
      <h2 className="text-[#F8981D] font-black text-[16px] sm:text-[20px] leading-[23.44px] mt-[49px]">
        THE SIMPLE PROCESS
      </h2>
      <h3 className="text-[#FFFFFF] font-bold text-[32px] sm:text-[40px] leading-[46.88px] mt-[17px]">
        How I Work
      </h3>
      <div className="w-full flex flex-wrap justify-center px-[20px] lg:px-[80px] mt-[54px] mb-[69px]">
        <div className="basis-1/2 lg:basis-1/4 flex flex-col items-center mb-[50px]">
          <div className="w-[100px] sm:w-[150px] h-[100px] sm:h-[150px]">
            <img
              className="w-[100%] h-[100%]"
              src="assets/img/process1.webp"
              alt="gallery"
            />
          </div>
          <div className="text-[#FFFFFF] text-[20px] sm:text-[25px] font-semibold leading-[29.3px] text-center mt-[30px]">
            Book Online
          </div>
          <div className=" max-w-[235px] text-[#FFFFFF] text-[16px] leading-[18.75px] text-center mt-[30px]">
            Provide details on your project, and schedule an appointment.
          </div>
        </div>
        <div className="basis-1/2 lg:basis-1/4 flex flex-col items-center mb-[50px]">
          <div className="w-[100px] sm:w-[150px] h-[100px] sm:h-[150px]">
            <img
              className="w-[100%] h-[100%]"
              src="assets/img/process2.webp"
              alt="gallery"
            />
          </div>
          <div className="text-[#FFFFFF] text-[20px] sm:text-[25px] font-semibold leading-[29.3px] text-center mt-[30px]">
            SMS Reminder
          </div>
          <div className=" max-w-[235px] text-[#FFFFFF] text-[16px] leading-[18.75px] text-center mt-[30px]">
            You will receive a text reminder 1 hour before our appointment.
          </div>
        </div>
        <div className="basis-1/2 lg:basis-1/4 flex flex-col items-center mb-[50px]">
          <div className="w-[100px] sm:w-[150px] h-[100px] sm:h-[150px]">
            <img
              className="w-[100%] h-[100%]"
              src="assets/img/process3.webp"
              alt="gallery"
            />
          </div>
          <div className="text-[#FFFFFF] text-[20px] sm:text-[25px] font-semibold leading-[29.3px] text-center mt-[30px]">
            Material Estimate
          </div>
          <div className=" max-w-[235px] text-[#FFFFFF] text-[16px] leading-[18.75px] text-center mt-[30px]">
            A cost estimate of your project needs. Some materials may need to be
            procured.
          </div>
        </div>
        <div className="basis-1/2 lg:basis-1/4 flex flex-col items-center mb-[50px]">
          <div className="w-[100px] sm:w-[150px] h-[100px] sm:h-[150px]">
            <img
              className="w-[100%] h-[100%]"
              src="assets/img/process4.webp"
              alt="gallery"
            />
          </div>
          <div className="text-[#FFFFFF] text-[20px] sm:text-[25px] font-semibold leading-[29.3px] text-center mt-[30px]">
            Completion
          </div>
          <div className=" max-w-[235px] text-[#FFFFFF] text-[16px] leading-[18.75px] text-center mt-[30px]">
            I will do the assigned task while you relax.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
