import { messaging, getToken } from "../firebase";

// Function to send the push subscription details to the server
export const sendPushSubscriptionToServer = (subscription) => {
  // Make an HTTP POST request to your server
  fetch(process.env.REACT_APP_API_BASE_URL + "api/notification/subscribe", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subscription),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Subscription successful:", data);
    })
    .catch((error) => {
      console.error("Error subscribing to push notifications:", error);
    });
};

export const subscribeToPushNotifications = async (userId, fname, lname) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("granted!");
      // Get FCM token
      const token = await getToken(messaging, {
        vapidKey:
          "BGhP_rI5l_EIpZUbYSo-ipnBY7KtrxzQM7fgzmBTgF1yA1JrFQAgqJbK0XeVgOsotjLePY3IytprautJnIg1iE8",
      });
      // Send the FCM token to your server
      const subscription = {
        endpoint: token,
        userId,
        fname,
        lname,
      };

      sendPushSubscriptionToServer(subscription);
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};
