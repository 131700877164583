import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import openSocket from "socket.io-client";
import { getAuth } from "./app/authSlice";
import PrivateRoute from "./utils/PrivateRoute";
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "./app/authSlice";
import { useDispatch } from "react-redux";
import { logoutUser } from "./app/authSlice";
import { getNotificationAsync } from "./app/notificationSlice";
import { Toaster } from "react-hot-toast";
import notify from "./utils/notify";
import { showSiteSetting, getSiteSettingAsync } from "./app/siteSettingSlice";

import Home from "./component/landing/Home";
import "./App.css";

// Import user pages
const TodoList = lazy(() => import("./component/dashboard/dashboard/TodoList"));
const NotificationBox = lazy(() =>
  import("./component/dashboard/dashboard/NotificationBox")
);
const Booking = lazy(() => import("./component/dashboard/booking/Booking"));
const PastBooking = lazy(() =>
  import("./component/dashboard/booking/PastBooking")
);
const FutureBooking = lazy(() =>
  import("./component/dashboard/booking/FutureBooking")
);
const PaymentHistory = lazy(() =>
  import("./component/dashboard/booking/PaymentHistory")
);
const StripePortal = lazy(() =>
  import("./component/dashboard/portal/StripePortal")
);
const Profile = lazy(() => import("./component/dashboard/setting/Profile"));
const ServiceAreas = lazy(() => import("./component/landing/ServiceAreas"));
const MyGallery = lazy(() => import("./component/landing/MyGallery"));
const Terms = lazy(() => import("./component/landing/Terms"));
const Faq = lazy(() => import("./component/landing/Faq"));
const HardRefresh = lazy(() => import("./component/landing/HardRefresh"));

// Import admin pages
const AdDashboard = lazy(() =>
  import("./component/dashboard/admin/dashboard/AdDashboard")
);
const AdPastBooking = lazy(() =>
  import("./component/dashboard/admin/booking/AdPastBooking")
);
const AdReport = lazy(() =>
  import("./component/dashboard/admin/booking/AdReport")
);
const AdFutureBooking = lazy(() =>
  import("./component/dashboard/admin/booking/AdFutureBooking")
);
const AdPaymentHistory = lazy(() =>
  import("./component/dashboard/admin/booking/AdPaymentHistory")
);
const AdServiceSetting = lazy(() =>
  import("./component/dashboard/admin/service/AdServiceSetting")
);
const AdLocationCharge = lazy(() =>
  import("./component/dashboard/admin/booking/AdLocationCharge")
);
const AdProfile = lazy(() =>
  import("./component/dashboard/admin/settings/AdProfile")
);
const AdSettings = lazy(() =>
  import("./component/dashboard/admin/settings/AdSettings")
);
const AdClients = lazy(() =>
  import("./component/dashboard/admin/clients/AdClients")
);
const AdGallery = lazy(() =>
  import("./component/dashboard/admin/gallery/AdMyGallery")
);

const socket = openSocket(process.env.REACT_APP_API_BASE_URL, {
  transports: ["websocket"],
});

const renderLoader = () => <p></p>;

function App() {
  const location = useLocation();
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const backend = isMobile ? TouchBackend : HTML5Backend;
  const settings = useSelector(showSiteSetting);

  useEffect(() => {
    dispatch(getSiteSettingAsync());
    // check for token
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and expiration
      const decoded = jwt_decode(localStorage.jwtToken);

      // Set user and isAuthenticated
      dispatch(setCurrentUser(decoded));

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout the user
        dispatch(logoutUser());
        // Redirect to homepage
        window.location.href = "/";
      }

      // Join the room with the specific user ID to receive notifications
      socket.emit("joinRoom", decoded.id, decoded.fname, decoded.lname);

      // Listen for incoming notifications
      socket.on("notification", (notificationData) => {
        // Handle the incoming notification and update the state
        dispatch(getNotificationAsync(decoded.id));
        notify("New notification received!");
      });

      // Clean up the socket connection when the component unmounts
      return () => {
        socket.off("notification");
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (settings) {
      const metaDescription =
        settings.metaDescription ||
        "Services: Clogged Drains, Plumbing Help, Electrical Help, General Repairs, Hauling, Pressure Washing, Furniture Assembly, Mounting, Light Carpentry";
      let metaTag = document.querySelector('meta[name="description"]');
      if (metaTag) {
        metaTag.setAttribute("content", metaDescription);
      } else {
        metaTag = document.createElement("meta");
        metaTag.name = "description";
        metaTag.content = metaDescription;
        document.head.appendChild(metaTag);
      }
    }
  }, [location.pathname, settings]);

  return (
    <Suspense fallback={renderLoader()}>
      <DndProvider backend={backend}>
        <div className="App">
          <Toaster position="top-right" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/ad_dashboard"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/past_booking"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdPastBooking />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/report"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdReport />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/future_booking"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdFutureBooking />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/payment_history"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdPaymentHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/service_setting"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdServiceSetting />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/location_charge"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdLocationCharge />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/profile/:user_id"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/setting"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdSettings />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/clients"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdClients />
                </PrivateRoute>
              }
            />
            <Route
              path="/ad_dashboard/gallery"
              element={
                <PrivateRoute
                  auth={localStorage.jwtToken}
                  permition="handyman_admin"
                >
                  <AdGallery />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <TodoList />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/notification"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <NotificationBox />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/stripe"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <StripePortal />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/booking/:categorySn"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <Booking />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/past_booking"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <PastBooking />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/future_booking"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <FutureBooking />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/payment_history"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <PaymentHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/profile/:user_id"
              element={
                <PrivateRoute auth={localStorage.jwtToken}>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route path="/service-areas" element={<ServiceAreas />} />
            <Route path="/gallery" element={<MyGallery />} />
            <Route path="/manual" element={<ServiceAreas />} />
            <Route path="/referral" element={<ServiceAreas />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/hard-refresh" element={<HardRefresh />} />
          </Routes>
        </div>
      </DndProvider>
    </Suspense>
  );
}

export default App;
