import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  notification: null,
  notifications: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

// Get notifications by user id
export const getNotificationAsync = (user_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/notification/get_notification/" +
        user_id
    );
    dispatch(setNotification(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get all notifications
export const getAllNotificationAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/notification/get_all_notification"
    );
    dispatch(setNotifications(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setNotification, setNotifications } = notificationSlice.actions;
export const showNotification = (state) => state.notification.notification;
export const showAllNotifications = (state) => state.notification.notifications;
export default notificationSlice.reducer;
