import React from "react";
import classNames from "classnames";

const Menu = ({ bookAppointment }) => {
  const [dropdownMenu, setDropdownMenu] = React.useState(false);
  return (
    <div className="max-w-[1066px] mx-[24px] lg:mx-auto relative">
      <div className="px-[20px] lg:flex lg:px-[40px] py-[14px] bg-[#F8981D] justify-between items-baseline font-sans font-semibold text-base z-10 translate-y-[50%]">
        <div className="items-center justify-between w-full flex">
          <ul className="hidden lg:flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0">
            <li>
              <a
                href="/"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                Home
              </a>
            </li>
            <li className="mx-[57px]">
              <a
                href="/#about"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                About
              </a>
            </li>
            <li className="mx-[57px]">
              <a
                href="/#services"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                Services
              </a>
            </li>
            <li className="mx-[57px]">
              <a
                href="/gallery"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                Gallery
              </a>
            </li>
            <li className="mx-[57px]">
              <a
                href="/#testimonials"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                Testimonials
              </a>
            </li>
            <li className="mx-[57px]">
              <a
                href="tel:415-712-0894"
                className="block py-2 pl-3 pr-4 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
              >
                Contact
              </a>
            </li>
          </ul>
          <button
            className="lg:hidden flex"
            onClick={() => setDropdownMenu(!dropdownMenu)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <span className="ml-[8px]">Menu</span>
          </button>
          <button
            id="book-btn"
            className="px-[22px] py-[13px] bg-white text-center text-[14px] lg:text-[16px] border hover:bg-[#F8981D] hover:border"
            onClick={bookAppointment}
          >
            MAKE APPOINTMENT
          </button>
        </div>
      </div>
      {/* Drop down menu */}
      <div
        className={classNames(
          "absolute top-[120px] w-full bg-[#F8981D] px-[20px]",
          { block: dropdownMenu, hidden: !dropdownMenu }
        )}
        onClick={() => setDropdownMenu(!dropdownMenu)}
      >
        <ul className="flex flex-col mt-4 font-medium">
          <li>
            <a href="/" className="block py-2 text-[#404040]">
              Home
            </a>
          </li>
          <li className="">
            <a href="#about" className="block py-2 text-[#404040]">
              About
            </a>
          </li>
          <li className="">
            <a href="/#services" className="block py-2 text-[#404040]">
              Services
            </a>
          </li>
          <li className="">
            <a href="/gallery" className="block py-2 text-[#404040]">
              Gallery
            </a>
          </li>
          <li className="">
            <a href="/#testimonials" className="block py-2 text-[#404040]">
              Testimonials
            </a>
          </li>
          <li className="">
            <a
              href="tel:415-712-0894"
              className="block py-2 text-[#404040] md:hover:bg-transparent md:border-0 md:hover:text-white"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
