import React from "react";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";

const Topbar = ({ setModal, logout }) => {
  if (localStorage.jwtToken) {
    var decoded = jwt_decode(localStorage.jwtToken);
  }

  return (
    <div
      id="top-bar"
      className="w-full py-[18px] px-[24px] md:px-[83px] bg-[#151515] flex flex-col md:flex-row justify-between"
    >
      <div className="text-[14px] md:text-[16px] leading-[19px] font-[600] text-white self-center">
        Welcome{decoded ? ", " + decoded.fname.toUpperCase() : ""}
      </div>
      <div className="flex flex-col md:flex-row items-center text-[14px] md:text-[16px] leading-[19px] font-[600] text-white mt-[17px] md:mt-[0px]">
        <span>
          <span className="text-[#F8981D]">NOTE: </span>
          There is a 2-hour minimum for any job
        </span>
        <div className="flex">
          <button
            id="login-btn"
            className="block ml-2 mt-[17px] md:mt-0 px-3 py-2 text-xs font-medium text-center text-[#F8981D] border border-[#F8981D] hover:text-white hover:border-white"
            type="button"
            onClick={() => {
              setModal("login");
              document.body.style.overflow = "hidden";
            }}
            style={{ display: decoded?.id ? "none" : "block" }}
          >
            Login
          </button>
          <button
            id="register-btn"
            className="block ml-2 mt-[17px] md:mt-0 px-3 py-2 text-xs font-medium text-center text-[#F8981D] border border-[#F8981D] hover:text-white hover:border-white"
            type="button"
            onClick={() => {
              setModal("register");
              document.body.style.overflow = "hidden";
            }}
            style={{ display: decoded?.id ? "none" : "block" }}
          >
            Register
          </button>
          {decoded?.role ? (
            decoded.role === "handyman_admin" ? (
              <Link
                to="/ad_dashboard"
                className="block ml-2 mt-[17px] md:mt-0 px-3 py-2 text-xs font-medium text-center text-[#F8981D] border border-[#F8981D] hover:text-white hover:border-white"
                style={{ display: decoded?.id ? "block" : "none" }}
              >
                My Account
              </Link>
            ) : (
              <Link
                to="/dashboard"
                className="block ml-2 mt-[17px] md:mt-0 px-3 py-2 text-xs font-medium text-center text-[#F8981D] border border-[#F8981D] hover:text-white hover:border-white"
                style={{ display: decoded?.id ? "block" : "none" }}
              >
                My Account
              </Link>
            )
          ) : (
            ""
          )}
          <button
            onClick={logout}
            className="block ml-2 mt-[17px] md:mt-0 px-3 py-2 text-xs font-medium text-center text-[#F8981D] border border-[#F8981D] hover:text-white hover:border-white"
            style={{ display: decoded?.id ? "block" : "none" }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
