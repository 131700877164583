import React from "react";
import Menu from "./Menu";

const Header = ({ bookAppointment }) => {
  return (
    <div className="w-full h-[80px] bg-[#FFFFFF]">
      <Menu bookAppointment={bookAppointment} />
    </div>
  );
};

export default Header;
